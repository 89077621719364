import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
	const post = data.ghostPost;
	const pagination = data.allGhostPost.edges.find(edges => edges.node.slug === post.slug);

	return (
		<Fragment>
			<MetaData
				data={data}
				location={location}
				type="article"
			/>
			<Helmet>
				<style type="text/css">{`${post.codeinjection_styles}`}</style>
			</Helmet>
			<Layout>
				<div className="container">
					<article className="content">
						{ post.feature_image ?
							<figure className="post-feature-image">
								<img src={ post.feature_image } alt={ post.title } />
							</figure> : null }
						<section className="post-full-content">
							<h1 className="post-content-tag">{post.primary_tag.meta_title}</h1>
							<h2 className="content-title">{post.title}</h2>
							{/* The main post content */ }
							<section
								className="content-body load-external-scripts"
								dangerouslySetInnerHTML={{ __html: post.html }}
							/>
						</section>
					</article>
					<nav className="pagination" role="navigation">
						<div>
							{pagination.previous ? (

								<Link to={new URL(pagination.previous.url).pathname} rel="prev">
																	Previous
								</Link>

							) : <Link to={location.pathname.replace(post.slug,``)} rel="prev">
																Previous
							</Link>}
						</div>

						<div>
							{pagination.next && (

								<Link to={new URL(pagination.next.url).pathname} rel="next">
																	Next
								</Link>
							)}
						</div>
					</nav>
				</div>
			</Layout>
		</Fragment>
	);
};

Post.propTypes = {
	data: PropTypes.shape({
		allGhostPost: PropTypes.object,
		ghostPost: PropTypes.shape({
			codeinjection_styles: PropTypes.object,
			title: PropTypes.string.isRequired,
			html: PropTypes.string.isRequired,
			feature_image: PropTypes.string,
			slug: PropTypes.string,
			primary_tag: PropTypes.object,
		}).isRequired,
	}).isRequired,
	location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query($slug: String!, $primary_tag: String) {
			allGhostPost(sort: {order: DESC, fields: published_at}, filter: {primary_tag: {slug: {eq: $primary_tag}}}) {
		    edges {
		      previous {
		        slug
		        title
						url
		      }
		      node {
		        slug
		      }
		      next {
		        slug
		        title
						url
		      }
		    }
		  }
      ghostPost(slug: { eq: $slug }) {
          ...GhostPostFields
      }
    }
`;
